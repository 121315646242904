$background: #000032;
$texts: #000;
$links: #aaa;
$highlighted-texts: #fff;
$titles: #333;

body {
  color: $texts;
  background: $background;
}

.button {
  border: solid 1px #000080;
  color: white;
  font-size: 1.5em;
  display: block;
  text-align: center;
  background: #000080;
  padding: 10px;
  margin: 10px;
  text-decoration: none;
  width: 200px;
  margin: 0 auto;
}

a {
  color: $links;
}

h1 {
  text-align: center;
  color: $titles;
}

#app {
  margin-bottom: 4rem;
}

#content {
  margin-top: 4rem;
}

#home {
/* Eléments principaux de la page */

  & #title {
    display: block;
    text-align: center;
  }

  & #content {

    & #titles {
      font-weight: bold;
      text-align: center;
      font-size: 2.2em;

      & span {
        display: inline-block;
        margin: 0rem 3rem;
        width: 110px;
        text-align: center;
        padding: 2.2rem 0rem;
        border-radius: 4rem;
        font-weight: bold;
      }

      & .head {
        font-weight: bold;
        text-align: left;
        font-size: .6em;
      }

      & span.head {
        padding: 0;
        text-align: center;
      }
      & span.title.head {
        color: transparent;
      }
    }

    & #facteurs {
      text-align: center;
      font-size: 2em;
  
      & span, & div.head {
        display: inline-block;
        margin: .5rem 3rem;
        text-align: center;
        border-radius: 4rem;
        font-weight: bold;
      }

      & span {
        padding: 2.2rem 0rem;
        width: 110px;
      }

      & div.head {
        text-align: left;
        width: 130px;

        & .head__value {
          font-size: .8em;
        }
      }

      & .head {
        font-weight: bold;
        text-align: left;
        font-size: .6em;
      }

      & .position, & .speed, & .acceleration {
        color: transparent;  
      }
    
    }

    & .home__note {
      width: 100%;
      text-align: center;

      & p {
        margin: 0 auto;
        max-width: 800px;
        font-size: .8em;
      }

      & a {
        text-decoration: none;
        color: $highlighted-texts;
        font-weight: bold;
      }

    }
  }

  @media screen and (max-width: 900px) {

    & #content {
      & #titles {
        width: 100%;
        font-size: .7em;
        & span {
          width: 80px;
          padding: 1.5rem 0rem;
          margin: 0.5rem 1.5rem;
        }
  
        & .head {
          font-size: 1.3em;
          display: inline-block;
          margin: 1.5rem;  
      }  
      }

      & #facteurs {
        font-size: .7em;

        & span {
          width: 80px;
          padding: 1.5rem 0rem;
          margin: 0.5rem 1.5rem;
        }
  
        & .head {
          font-size: 1.3em;
        }  
      }

    }
  }

  @media screen and (max-width: 623px) {

    & #content {
      & #titles {
        margin: 0;
        & .head {
          display: inline-block;
          margin: 1rem 1rem;
          font-size: 1.3em;
        }  
        & span {
          &.title {
            display: none;
          }
        }

      }

      & #facteurs {
        margin: 0;
        font-size: .7em;
        
        & span {
          width: 80px;
          padding: 1.5rem 0rem;
          margin: 0.5rem .8rem;
        }
  
        & div.head {
          display: flex;
          font-size: 1.3em;
          width: 20rem;
          margin: .5rem 2rem;
  
          & p {
            display: block;
            margin: 0rem 1rem;
          }
        }  
      }

    }
  }
}
