
.calc {
    color: white;
    background: #000032;
    margin: 0 auto;
    max-width: 30rem;
    &-item {
        color: white;
        background: blue;
        width: 7rem;
        height: 7rem;
        margin: .5rem;
        font-size: 1.5em;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    &-result {
        color: white;
        text-align: right;
        border: solid 1px black;
        margin: .2rem;
        padding: 2rem;
        background-color: #000080;
        font-size: 2.5em;
    }
}